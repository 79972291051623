$(document).ready(function() {
    // On change
    $("#search_terms").on('change', function(){
        filter_search_terms();    
    });
});
function filter_search_terms(){
    selected = $("#search_terms").val();

    if(selected == 0){
        $(".year").css("display", "block");
        $(".start_date").css("display", "block");
        $(".end_date").css("display", "block");
        $(".organization").css("display", "block");
        $(".region").css("display", "block");
        $(".town").css("display", "block");
        $(".neighborhood").css("display", "block");
        $(".commune").css("display", "block");
        $(".structure").css("display", "block");
        $(".doctor").css("display", "block");
    }else if (selected == 1){
        $(".year").css("display", "block");

        $(".start_date").css("display", "none");
        $(".end_date").css("display", "none");
        $(".organization").css("display", "none");
        $(".region").css("display", "none");
        $(".town").css("display", "none");
        $(".neighborhood").css("display", "none");
        $(".commune").css("display", "none");
        $(".structure").css("display", "none");
        $(".doctor").css("display", "none");
    }else if (selected == 2){
        $(".start_date").css("display", "block");
        $(".end_date").css("display", "block");

        $(".year").css("display", "none");
        $(".organization").css("display", "none");
        $(".region").css("display", "none");
        $(".town").css("display", "none");
        $(".neighborhood").css("display", "none");
        $(".commune").css("display", "none");
        $(".structure").css("display", "none");
        $(".doctor").css("display", "none");
    }else if (selected == 3){
        $(".organization").css("display", "block");

        $(".year").css("display", "none");
        $(".start_date").css("display", "none");
        $(".end_date").css("display", "none");
        $(".region").css("display", "none");
        $(".town").css("display", "none");
        $(".neighborhood").css("display", "none");
        $(".commune").css("display", "none");
        $(".structure").css("display", "none");
        $(".doctor").css("display", "none");
    }else if (selected == 4){
        $(".region").css("display", "block");
        $(".town").css("display", "block");
        $(".neighborhood").css("display", "block");
        $(".commune").css("display", "block");

        $(".year").css("display", "none");
        $(".start_date").css("display", "none");
        $(".end_date").css("display", "none");
        $(".organization").css("display", "none");
        $(".structure").css("display", "none");
        $(".doctor").css("display", "none");
    }else if(selected == 5){
        $(".structure").css("display", "block");

        $(".year").css("display", "none");
        $(".start_date").css("display", "none");
        $(".end_date").css("display", "none");
        $(".organization").css("display", "none");
        $(".region").css("display", "none");
        $(".town").css("display", "none");
        $(".neighborhood").css("display", "none");
        $(".commune").css("display", "none");
        $(".doctor").css("display", "none");
    }else if(selected == 6){
        $(".doctor").css("display", "block");

        $(".year").css("display", "none");
        $(".start_date").css("display", "none");
        $(".end_date").css("display", "none");
        $(".organization").css("display", "none");
        $(".region").css("display", "none");
        $(".town").css("display", "none");
        $(".neighborhood").css("display", "none");
        $(".commune").css("display", "none");
        $(".structure").css("display", "none");
    }else{
        $(".year").css("display", "none");
        $(".start_date").css("display", "none");
        $(".end_date").css("display", "none");
        $(".organization").css("display", "none");
        $(".region").css("display", "none");
        $(".town").css("display", "none");
        $(".neighborhood").css("display", "none");
        $(".commune").css("display", "none");
        $(".structure").css("display", "none");
        $(".doctor").css("display", "none");
    }
}