// var  totalAmount = 0;

// $(document).ready(function(){
// //	alert('test');
// function updateAmount() {
//   var selectedYears = $('#year').val();
//   var totalAmount = 0;

//   // Itération sur chaque année sélectionnée
//   $.each(selectedYears, function(index, year) {
//       // Appel AJAX pour obtenir le montant de cotisation par an
//       $.ajax({
//           url: '/contributions/getAmount',
//           method: 'GET',
//           dataType: "json",
//           success: function(amount) {
//               // Ajouter le montant au total
//               totalAmount += amount;
//               // Appel AJAX pour obtenir la pénalité
//               $.ajax({
//                   url: '/contributions/getPenaltyAmount/' + year,
//                   method: 'GET',

//                   dataType: "json",
//                   success: function(penalty) {
//                       // Ajouter la pénalité au montant total
//                       totalAmount += penalty;

//                       // Mettre à jour le champ d'entrée du montant
//                       $('#amount').val(totalAmount);
//                   },
//                   error: function() {
//                       console.log('Erreur lors de l\'appel de la fonction get_penalty');
//                   }
//               });
//           },
//           error: function() {
//               console.log('Erreur lors de l\'appel de la fonction get_year_amount');
//           }
//       });
//   });
// }

// // Écouteur d'événement pour détecter les changements dans la sélection d'années
// $('#year').on('change', function() {
//   updateAmount();
// });

// // Appel initial pour mettre à jour le montant au chargement de la page
// updateAmount();

// });

$(document).ready(function () {
    async function getYearAmount() {
        try {
            const response = await $.ajax({
                url: "/contributions/getAmount",
                method: "GET",
                dataType: "json",
            });
            return response;
        } catch (error) {
            console.error(
                "Erreur lors de l'appel de la fonction getYearAmount :",
                error
            );
            return 0;
        }
    }

    async function getPenaltyAmount(year, id) {
        try {
            const response = await $.ajax({
                url: `/contributions/getPenaltyAmount/${year}/${id}`,
                method: "GET",
                dataType: "json",
            });
            return response;
        } catch (error) {
            console.error(
                `Erreur lors de l'appel de la fonction getPenaltyAmount pour l'année ${year} :`,
                error
            );
            return 0;
        }
    }

    $("#doctor_id").on("change", function () {
        doctor_id = $(this).val();
        // console.log("Médecin sélectionné : ", doctor_id);
    });

    async function updateAmount() {
        const selectedYears = $("#year").val();

        if (!doctor_id) {
            console.error("Aucun médecin sélectionné !");
            return;
        }

        let totalAmount = 0;

        if (!selectedYears || selectedYears.length === 0) {
            $("#amount").val(totalAmount);
            return;
        }

        // Parcourir chaque année sélectionnée
        for (const year of selectedYears) {
            // Récupérer le montant et la pénalité pour chaque année
            const yearAmount = await getYearAmount();
            const penaltyAmount = await getPenaltyAmount(year, doctor_id);

            // Ajouter au total
            totalAmount += yearAmount + penaltyAmount;
        }

        // Mettre à jour le champ avec le montant total
        $("#amount").val(totalAmount);
    }

    // Écouteur d'événement pour détecter les changements dans la sélection d'années
    $("#year").on("change", function () {
        updateAmount();
    });

    // Appel initial pour mettre à jour le montant au chargement de la page
    updateAmount();
});
