$(document).ready(function() {
	$("#document_type_id").on('change', function(){
        $(".form_item").css("display", "none");
        $(".form_attachment").css("display", "none");
        $(".items").val('');
        $(".items option:selected").prop('selected', false);
        $(".items").each(function() {
        $(this).find('option').eq(0).prop('selected', true);
        });
        $(".attachments").val('');
        //$(".items").required=false;
       
        selected_document_type = $("#document_type_id").val();
		if(selected_document_type) {
            $.ajax({
                url: '/items/get/'+selected_document_type,
                type:"GET",
                dataType:"json",
                beforeSend: function(){
                    $('#loader').css("visibility", "visible");
                },
				
                success:function(data) {
                    $.each(data, function(key, value){
                        $('#form_item_'+key).css("display", "block");
                        $('#item_'+key).required = true;
                    });
                },
                complete: function(){
                    $('#loader').css("visibility", "hidden");
                }
            });
            $.ajax({
                url: '/attachments/get/'+selected_document_type,
                type:"GET",
                dataType:"json",
                beforeSend: function(){
                    $('#loader').css("visibility", "visible");
                },
				
                success:function(data) {
                    $.each(data, function(key, value){
                        $('#form_attachment_'+key).css("display", "block");
                        $('#attachment_'+key).required = true;
                    });
                },
                complete: function(){
                    $('#loader').css("visibility", "hidden");
                }
            });
        }
		/*var items = "{{ \Helper::get_doc_items(selected_document_type) }}";
		array.forEach(items, function(item){
			alert(item);
			$("#item"+item).show();
		});
        if (selected_document_type == 1){
	       
	        $(".document-request-structure").css("display", "none");
	        $("#structure_category_id").val('');
	        $("#structure_name").val('');
	        
	    }
	    else if (selected_document_type == 2){
	        $(".document-request-structure").css("display", "block");
	    }
	    */

    });

    $(".form_item").css("display", "none");
    $(".form_attachment").css("display", "none");
})

//.empty().trigger('change')